import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import $ from 'jquery';
import slick from 'slick-carousel';
import magnificPopup from 'magnific-popup';
import Headroom from 'headroom.js';

const Psychogenesis = {
  init() {
    this.cache();
    this.events();
    this.initHeadroom();
    this.initSliders();
    this.initSearch();
    this.initProgressiveLoad();
    this.initPopups();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-mobile-nav');
    this.$hamburger = $('.js-hamburger');
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );
    this.$doc.on(
      'click',
      '.js-video-item',
      event => this.playVideoItem(event)
    );
  },
  
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },

  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader);
    headroom.init();
  },

  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    if ( this.$mobileNav.hasClass('is-active') ) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },

  initSliders() {
    $('.js-hero-slider').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      // speed: 900,
      fade: true
    });

    $('.js-testimonial-slider').slick({
      dots: false,
      arrows: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      // speed: 900,
      fade: true
    });

    $('.js-featured-slider').slick({
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 900,
      // fade: true,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });

    $('.js-videos-slider').slick({
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 900,
      // fade: true,
      responsive: [
        {
          breakpoint: 1000,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });
  },

  initPopups() {
    $('.gallery--item').magnificPopup({
      type: 'image',
      midClick: true,
      mainClass: 'popup--animate',
      fixedContentPos: true,
      closeBtnInside: true,
      gallery:{
        enabled:true
      }
    });
  },

  initSearch() {
    var input = $('#sidebar-search');

    input.change( function() {
      input.addClass('has-content');
    });
  },

  initProgressiveLoad() {
    $('.progressive-image').each(function() {
      var image = new Image();
      var previewImage = $(this).find('.loadingImage');
      var newImage = $(this).find('.overlay');
      // console.log(previewImage);
      // console.log(newImage);
      image.onload = function() {
        newImage.css('background-image', 'url(' + image.src + ')');
        newImage.css('opacity', '1');
        previewImage.hide();
      };

      image.src = previewImage.data('image');
    });
  },

  playVideoItem(event) {
    event.preventDefault();
    var videoItem = $(event.currentTarget);
    var videoType = videoItem.data('type');
    var videoSource = videoItem.data('source');
    var videoSrc = videoItem.data('video');

    if (videoType == 'external' && videoSource == 'youtube') {

      $.magnificPopup.open({
        items: {
          src: videoSrc,
          type: 'iframe',
        },
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--animate',
        iframe: {
          markup: '<div class="mfp-video-header">' +
            '<div class="mfp-close"></div>' +
            '</div>' +
            '<div class="mfp-iframe-scaler">' +
            '<iframe class="mfp-iframe" frameborder="0" allowfullscreen></iframe>' +
            '</div>', // HTML markup of popup, `mfp-close` will be replaced by the close button
          patterns: {
            youtube: {
              index: 'youtube.com',
              id: 'v=',
              src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
            }
          }
        }
      });
    }
  },
};

Psychogenesis.init();
